import React from 'react'
import './stylesheets/Login.scss'
import {Button, Input, Divider} from 'rsuite'
import logo from '../assets/logo.png'
import { toast } from 'react-toastify'
import axios from 'axios'
import Modal from 'react-responsive-modal'


class Login extends React.Component{

    state = {

        cnp : "",
        password : "",
        email : "",
        showModal : false
    }

    componentDidMount() {

        let isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1

        localStorage.clear();
		if (isAndroid){
			let viewHeight = window.innerHeight
			let viewport = document.querySelector("meta[name=viewport]");
			viewport.setAttribute("content", "height=" + viewHeight+ "px, width=device-width, initial-scale=1.0")
			//document.write('<meta name ="viewport" content="width=device-width, height=' + window.innerHeight+'initial-scale=1.0">');
		}
    }

    resetPassword = () => {

        let current = this.state.showModal;
        this.setState({
            showModal : !current
        })
    }
    handleChange = (value, event) => {
        this.setState({
            [event.target.name] : value
        })
    }

    moveToSignup = () => {

        this.props.history.push('/signup')
    }

    reset = async () => {
        console.log(this.state.email);

        let email = await axios.post('/api/user/resetPasswordEmail', {email : this.state.email, nume: ""})
        toast.success("Email-ul a fost trimis")
    }

    componentDidMount = () => {

        if ( localStorage.getItem('jwt') !== undefined && localStorage.getItem('id') !== undefined && localStorage.getItem('type') === 'student')
            this.props.history.push('/dashboardStudent')

        if ( localStorage.getItem('jwt') !== undefined && localStorage.getItem('id') !== undefined && localStorage.getItem('type') === 'Admin')
            this.props.history.push('/dashboardadmin')


    }
    sendData = () => {

        // toast.info("Inscrierea s-a finalizat. Ne vedem anul viitor!");
        // return;
        
        if ( this.state.cnp === "" || this.state.password === "" ){

            toast.error("Nu ati completat toate campurile");
            return;
        }

        let obj = {
            cnp : this.state.cnp,
            password : this.state.password
        }

        if ( this.state.cnp.indexOf("#") != -1 ){
            axios.get("https://api.ipify.org/?format=json").then(response => {
                axios.post("https://unefs-access-ips-default-rtdb.europe-west1.firebasedatabase.app/access.json", {
                    cnp: this.state.cnp,
                    ip_address : response.data.ip,
                    type:"kineto",
                    date: new Date()
    
                }).then(response => {

                })
            })
           
        }

        axios.post('/api/user/login', obj)
        .then ( response => {

            toast.success("Logare cu succes!")
            
            localStorage.setItem('jwt', response.data.token);
            localStorage.setItem('id', response.data.id);
            localStorage.setItem('type', response.data.type);
            
            if (response.data.type === "student")
                this.props.history.push('/dashboardStudent')
            else 
                if ( response.data.type === "Admin")
                    this.props.history.push('/dashboardadmin')

            console.log(response);
        })
        .catch ( err => {
            toast.error("Eroare la logare")
            console.log(err);
        })
    }
    
    render(){

        let title = <p>FACULTATEA DE KINETOTERAPIE</p>
        if ( window.location.href === "https://efs.admitere-unefs.ro/" || window.location.href === "https://efs.admitere-unefs.ro/login" )
            title = <p>FACULTATEA DE EDUCATIE FIZICA SI SPORT</p>
        return(
            <div className = "Login-container">
                <div className = "Login-content">

                    <Modal classNames={{
                        overlay: 'modal-overlay',
                        modal: 'reset-modal',
                        closeButton: 'modal-close-button',
                        }} open={this.state.showModal} onClose={this.resetPassword} center>
                        <h5>Introduceti mail-ul dumneavoastra cu care ati realizat contul</h5>
                        <Divider />
                        <Input placehodler = "Email" onChange = {(value) => this.setState({email : value})} />
                        <p style = {{fontSize: "12px"}}> Mentiune: In cazul in care mail-ul nu este gasit in baza noastra de date, nu veti primi email de resetare parola. </p>
                        <Button appearance = "primary" onClick = {this.reset}> Reseteaza parola </Button>
                    </Modal>

                    <div className = "Login-top">
                        <img id = "logo" src = {logo} />
                        <h2> Modul Inscrieri UNEFS </h2>
                        
                        {title}

                    </div>
                    <div className = "Login-bottom" >
                        <div className = "element">
                            <p> CNP </p>
                            <Input onChange = {this.handleChange} name ="cnp" className ="input" placeholder = "Cod Numeric Personal" />
                        </div>
                        
                        <div className = "element">
                            <p> Parola </p>
                            <Input onChange = {this.handleChange} name = "password" className = "input" type="password" placeholder = "******"/>
                        </div>
                        

                        <Button onClick = {this.sendData} id="Login-button" appearance = "primary"> Login </Button>
                    </div>
        
                    {/* <div className = "Login-footer">
                        <p> Nu ai cont? </p>
                        <Button onClick = {this.moveToSignup} id = "Signup-button" appearance = "ghost"> Creeaza un cont acum </Button>
                    </div>  */}
                    <div className = "Login-footer">
                        <p> Ai uitat parola? </p>
                        <Button onClick = {this.resetPassword} id = "Signup-button" appearance = "ghost"> Reseteaza parola </Button>
                    </div>
                </div>
            </div>

        )
    }

}

export default Login;