import React from 'react'
import './stylesheets/Initial_Context.scss'
import {Button} from 'rsuite'
import axios from 'axios'

class Initial_Context extends React.Component{


    state = {


    }


    render(){

        return(
            <div className = "Initial_Context-container">
                <div className = "Initial_Context-content">
                    <h2> Buna ziua, {this.props.prenume}! </h2>
                    <p> Bine ai venit in modulul online de inscriere la UNEFS</p>

                    <p id = "startText"> Pentru a incepe inscrierea, alege o optiune de mai jos: </p>
                    {/* <Button onClick = {() => this.props.changeStatus("licenta")} appearance = "primary" > Ciclul I Studii Universitare de Licenta </Button> */}
                    <Button onClick = {() => this.props.changeStatus("masterat")} appearance = "primary" > Ciclul II Studii Universitare de Masterat </Button>
                    {/* <Button  onClick = {() => this.props.changeStatus("doctorat")} appearance = "primary" > Ciclul III Studii Universitare de Doctorat </Button> */}
                    <br/><br/>
                    {/* <Button onClick = {()=> this.props.changeStatus("other")} appearance = "primary" > Romani de Pretutindeni </Button>
                    <Button onClick = {()=> this.props.changeStatus("other")} appearance = "primary" > Cetateni state terte UE </Button>
                    <Button onClick = {()=> this.props.changeStatus("other")} appearance = "primary" > Programe in limbi straine </Button> */}

                </div>
            </div>
        )
    }
}

export default Initial_Context;